export const isShowQnaWriter = (user, writer) => {
  // 1. 비로그인시 무조건 노출하지 않음
  if (!user || user.groupName === 'bot') return false
  // 2. admin일땐 무조건 노출
  if (user.groupName === 'admin') return true
  // 3. 로그인한 사람과 작성가가 같을떄 노출
  if (user.userId === writer) return true

  return false
}
