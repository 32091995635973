<script>
export default {
  components: {},
  data() {
    const totalCount = Math.ceil(this.totalItems / this.itemsPerPage)

    return {
      wrapper: null, // pagaination wrapper
      totalCount: !totalCount ? 1 : totalCount, // 페이지 item 총 갯수
      currentItem: 1, // 현재 item
      itemsShown: 5, // 보여질 pagination item 수
      startItemShown: 1, // 시작 pagination item
      endItemShown: 5,
      items: []
    }
  },
  props: ['total', 'totalItems', 'itemsPerPage'],
  emits: ['onItemClick'],
  methods: {
    itemClick(e) {
      this.removeActive()
      const target = e.target.matches('li') ? e.target : e.target.closest('li')
      this.setActive(target)

      this.$emit('onItemClick', { itemIdx: this.currentItem })
    },
    removeActive() {
      const actived = this.wrapper.querySelector('.active')
      if (actived) actived.classList.remove('active')
    },
    setActive(target) {
      target.classList.add('active')
      this.currentItem = Number(target.textContent)
    },
    goPrev() {
      this.startItemShown -= this.itemsShown
      this.endItemShown = this.startItemShown + this.itemsShown - 1
      if (this.startItemShown < 1) this.startItemShown = 1
      this.setItems()

      this.$emit('onItemClick', { itemIdx: this.currentItem })
    },
    goNext() {
      this.endItemShown += this.itemsShown
      this.startItemShown = this.endItemShown - this.itemsShown + 1
      if (this.endItemShown > this.totalCount) this.endItemShown = this.totalCount
      this.setItems()

      this.$emit('onItemClick', { itemIdx: this.currentItem })
    },
    setItems() {
      this.items = []
      for (let i = this.startItemShown; i <= this.endItemShown; i++) {
        this.items.push(i)
      }

      this.currentItem = this.startItemShown
    }
  },
  mounted() {
    this.wrapper = this.$refs.pagination
    if (this.totalCount < this.endItemShown) this.endItemShown = this.totalCount
    this.setItems()
  },
  // page에 변화가 생길때 마다 실행
  updated() {
    this.removeActive()
    this.wrapper.children[1].classList.add('active')
  },
  watch: {
    totalItems() {
      const totalCount = Math.ceil(this.totalItems / this.itemsPerPage)
      this.totalCount = !totalCount ? 1 : totalCount

      this.endItemShown = 5
      if (this.totalCount < this.endItemShown) this.endItemShown = this.totalCount
      this.setItems()
    }
  },
  setup() {},
  created() {},
  unmounted() {}
}
</script>

<template>
  <section class="wf__pagination-wrapper">
    <ul class="wf__pagination" ref="pagination">
      <li class="wf__pagination-item" v-show="startItemShown !== 1">
        <span class="material-icons-outlined" @click="goPrev"> chevron_left </span>
      </li>

      <li class="wf__pagination-item" v-for="i in items" :key="i" v-text="i" @click="itemClick"></li>

      <li class="wf__pagination-item" v-show="endItemShown !== totalCount">
        <span class="material-icons-outlined" @click="goNext"> chevron_right </span>
      </li>
    </ul>
  </section>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.wf__pagination-wrapper {
  display: flex;
  justify-content: center;
}
.wf__pagination {
  display: flex;
  gap: 1.25em;
}
.wf__pagination-item {
  font-size: var(--wf-text-16);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 1.75em;
  height: 1.75em;
  border-radius: 0.25em;
}
.wf__pagination-item:hover {
  background-color: var(--wf-gray-500);
}
.wf__pagination-item.active {
  color: var(--wf-primary-color);
}
</style>
