<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import CommunityListTableVue from '@/components/Community/CommunityListTable.vue'
import PaginationBarVue from '@/components/ui/PaginationBar.vue'
import LoadingVue from '@/components/ui/Loading.vue'
import { getQna } from '@/api/platform/community'
import LanguageConverter from '@/util/LanguageConverter'
import { isShowQnaWriter } from './common'

const app = getCurrentInstance()
const getDate = app.appContext.config.globalProperties.$getDate

const store = useStore()
const route = useRoute()
const dataCount = ref(0)
const isLoading = ref(false)
const user = store.state.userStore.info

const paginationClickHandler = async (e) => {
  qnaList.value = await loadQnaList(e.itemIdx)
}

const lockIconHtml = '<span class="material-icons-outlined"> lock </span>'
const secretBoardStyle = `
  style="color: var(--wf-gray-color);
  display: flex;
  align-items: center; "
`

const checkShowSecret = (qna) => {
  if (qna.isSecret !== 1) return false
  return true
}

const checkUserAdmin = (user) => {
  if (user.groupName === 'admin') return true
}

const setTitleHTML = (qna) => {
  const isSecret = checkShowSecret(qna)
  const isAdmin = checkUserAdmin(user)

  return `<span ${isSecret ? secretBoardStyle : ''}>
    ${isSecret && !isAdmin ? LanguageConverter({ en: 'Secret', kr: '비밀글입니다.' }) : qna.title}
    ${isSecret ? lockIconHtml : ''}
  </span>`
}

const mapQnaData = (item, idx, offset) => {
  const title = setTitleHTML(item)
  return {
    no: offset + idx + 1,
    Id: item.qnaId,
    title: title,
    writer: isShowQnaWriter(user, item.userId) ? item.userId : null,
    name: item.name,
    createdAt: getDate(item.createdAt)
  }
}

const loadQnaList = async (pageIdx = 1) => {
  isLoading.value = true

  const offset = (pageIdx - 1) * 10
  const qnaList = await getQna({ limit: 10, offset })

  if (qnaList.code) {
    isLoading.value = false
    return null
  }
  if (qnaList.status) {
    isLoading.value = false
    return null
  }

  dataCount.value = qnaList.count

  const resultList = qnaList.items.map((item, idx) => mapQnaData(item, idx, offset))

  isLoading.value = false
  return resultList
}

const qnaList = ref([])

onMounted(async () => {
  qnaList.value = await loadQnaList()
})
</script>

<template>
  <LoadingVue v-if="isLoading" />
  <section class="body-section">
    <h2 class="wf__community-default-title">QnA</h2>

    <router-link class="wf__qna-write" :to="`${route.path}/w`">
      {{ LanguageConverter({ en: 'Write Question', kr: '질문등록하기' }) }}
    </router-link>

    <CommunityListTableVue v-if="qnaList" class="mb-5" :postList="qnaList" :showWriter="true" :baseUrl="route.path" />
    <div v-else>결과가 없습니다.</div>

    <PaginationBarVue v-if="dataCount" :totalItems="dataCount" :itemsPerPage="10" @onItemClick="paginationClickHandler" />
  </section>

  <section class="search-section">
    <!-- <SearchBarVue /> -->
  </section>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.wf__qna-write {
  position: absolute;
  top: 1em;
  right: 0;
  color: #fff;
  background-color: var(--wf-primary-color);
  border-radius: 0.25em;

  padding: 0.5em 1em;

  font-weight: 400;
  font-size: var(--wf-text-16);

  transition: all 0.2s ease;
}
.wf__qna-write:hover {
  opacity: 0.75;
}
.body-section {
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  margin-top: 5em;
  margin-bottom: 3em;
}
.body-section h2 {
  font-weight: bold;
  margin-bottom: 1em;
}
.search-section {
  margin-bottom: 5em;
}
</style>
