<script setup>
import { defineProps } from 'vue'
import { useRouter } from 'vue-router'

import LanguageConverter from '@/util/LanguageConverter'
import sanitizer from '@/util/htmlSanitizer'

const props = defineProps({
  postList: Array,
  baseUrl: String,
  showWriter: {
    type: Boolean,
    default: false
  }
})
const router = useRouter()

const RouteToDetail = (post) => {
  const url = `${props.baseUrl}/${post}`
  router.push(url)
}
</script>

<template>
  <div class="wf__table">
    <div class="wf__tr">
      <div class="wf__th">
        <span>{{ LanguageConverter({ en: 'Num', kr: '번호' }) }}</span>
      </div>
      <div class="wf__th">
        <span>{{ LanguageConverter({ en: 'Title', kr: '제목' }) }}</span>
      </div>
      <div v-if="showWriter" class="wf__th">
        <span>{{ LanguageConverter({ en: 'Writer', kr: '작성자' }) }}</span>
      </div>
      <div class="wf__th">
        <span>{{ LanguageConverter({ en: 'Date', kr: '작성일자' }) }}</span>
      </div>
    </div>

    <div class="wf__tr cursor-pointer" v-for="(post, index) in postList" :key="index" @click="RouteToDetail(post.Id)">
      <div class="wf__td text-center">
        <span v-html="sanitizer(post.no)"></span>
      </div>
      <div class="wf__td">
        <span v-html="sanitizer(post.title)"></span>
      </div>
      <div v-if="showWriter" class="wf__td text-center">
        <span v-html="sanitizer(post.writer ?? '***')"></span>
      </div>
      <div class="wf__td text-center">
        <span v-html="sanitizer(post.createdAt)"></span>
      </div>
    </div>
  </div>
</template>

<style>
:root {
  --wf-clt-col-width-1: 70px;
  --wf-clt-col-width-2: 20em;
  --wf-clt-col-width-3: 120px;
  --wf-clt-col-width-4: 120px;
}
</style>
<style scoped>
.wf__table {
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;

  border: 1px solid var(--wf-secondary-color);
  border-left: none;
  border-right: none;
  margin: 10px 0;

  overflow-x: scroll;

  font-size: var(--wf-text-16);
}
.wf__table::-webkit-scrollbar {
  display: none;
}
.wf__tr {
  display: flex;
  overflow: visible;
  padding: 1em 0;
  border-bottom: 1px solid var(--wf-gray-400);

  min-width: calc(var(--wf-clt-col-width-1) + var(--wf-clt-col-width-2) + var(--wf-clt-col-width-3) + var(--wf-clt-col-width-4));
}
.wf__tr:last-child {
  border-bottom: none;
}
.wf__th {
  text-align: center;
  font-weight: 600;
}
.wf__tr > .wf__td,
.wf__tr > .wf__th {
  flex: 1;
  padding: 0 1em;
}
.wf__tr > .wf__th span,
.wf__tr > .wf__td span {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.wf__tr > .wf__th {
  border-right: 1px solid var(--wf-secondary-color);
}
.wf__tr > .wf__td:first-child,
.wf__tr > .wf__th:first-child {
  min-width: var(--wf-clt-col-width-1);
  max-width: var(--wf-clt-col-width-1);
}

.wf__tr > .wf__td:nth-child(2),
.wf__tr > .wf__th:nth-child(2) {
  min-width: var(--wf-clt-col-width-2);
}
.wf__tr > .wf__td:nth-child(3),
.wf__tr > .wf__th:nth-child(3) {
  min-width: var(--wf-clt-col-width-3);
  max-width: var(--wf-clt-col-width-3);
}
.wf__tr > .wf__td:nth-child(4),
.wf__tr > .wf__th:nth-child(4) {
  min-width: var(--wf-clt-col-width-4);
  max-width: var(--wf-clt-col-width-4);
}
.wf__tr > .wf__td {
  min-width: max-content;
}
</style>
